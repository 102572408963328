/* eslint-disable import/prefer-default-export */
import { IDR } from '@/libs/currency'

export const chooseAction = [
  {
    label: 'Transfer Sekarang',
    value: 'transfer_now',
  },
  {
    label: 'Transfer Manual',
    value: 'transfer_manual',
  },
  {
    label: 'Review',
    value: 'review',
  },
  {
    label: 'Tolak',
    value: 'tolak',
  },
]

export const listDetailWithdrawal = [
  {
    key: 'timestamp',
    label: 'Tanggal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'mutation_description',
    label: 'Uraian Mutasi',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'nominal',
    label: 'Jumlah Nominal',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
  {
    key: 'saldo',
    label: 'Saldo Akhir',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
    tdClass: 'text-black',
    class: 'p-1',
  },
]

export const listWithdrawal = [
  {
    key: 'id',
    label: 'No',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'affiliator_name',
    label: 'Nama',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'bank_name',
    label: 'Nama Bank',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '100px',
    },
  },
  {
    key: 'bank_account_no',
    label: 'No Rekening',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
  },
  {
    key: 'date_wd',
    label: 'Tanggal Penarikan',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
  },
  {
    key: 'nominal',
    label: 'Nominal',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '120px',
    },
    formatter: value => IDR(value),
  },
  {
    key: 'status',
    label: 'Status',
    class: 'border-b-0 text-black p-1 text-start',
    thStyle: {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: 'black',
      minWidth: '150px',
    },
  },
]

export const statusOptionsListWithdrawal = [
  {
    value: null,
    text: 'Pilih Status',
    disabled: true,
  },
  {
    value: 1,
    text: 'Semua',
  },
  {
    value: 2,
    text: 'Request Penarikan',
  },
  {
    value: 3,
    text: 'Sedang Direview',
  },
  {
    value: 5,
    text: 'Disetujui',
  },
  {
    value: 4,
    text: 'Ditolak',
  },
  {
    value: 6,
    text: 'Diproses',
  },
]
