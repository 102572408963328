<template>
  <BCard>
    <h4 class="font-bold text-black text-2xl">
      {{ title }}
    </h4>
    <div class="flex justify-end mt-2">
      <DateRangePicker
        ref="picker"
        v-model="dateRange"
        :locale-data="locale"
        :ranges="ranges"
        :opens="'center'"
        class="withdrawal-date-range"
      >
        <template v-slot:input="picker">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div class="mr-1">
              <span
                v-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)"
                style="color: #828282 !important"
              >
                Hari ini
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)"
                style="color: #828282 !important"
              >
                2 Hari Terakhir
              </span>
              <span
                v-else-if="YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)"
                style="color: #828282 !important"
              >
                7 Hari Terakhir
              </span>
              <span
                v-else-if="
                  YEAR_MONTH_DAY(picker.startDate) ===
                    YEAR_MONTH_DAY(firstDateOfMonth) &&
                    YEAR_MONTH_DAY(picker.endDate) === YEAR_MONTH_DAY(today)
                "
                style="color: #828282 !important"
              >
                Bulan ini
              </span>
              <span
                v-else
                style="color: #828282 !important"
              > Semua </span>
            </div>
            <img
              src="https://storage.googleapis.com/komerce/assets/icons/calendar.png"
              alt="Komerce"
            >
          </div>
        </template>
      </DateRangePicker>
      <BFormSelect
        v-model="statusFilter"
        :options="statusOptions"
        placeholder="Pilih Status"
        class="ml-1 mr-1"
        style="width: 20%"
      />
      <BFormInput
        v-model="search"
        placeholder="Cari Nama atau Email"
        style="width: 22%"
        @input="searchData"
      />
    </div>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1"
        hover
        selectable
        select-mode="single"
        @row-selected="handleToDetail"
      >
        <template #cell(id)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(affiliator_name)="data">
          <span class="font-semibold">{{ data.item.affiliator_name }}</span>
          <br>
          <span class="text-[#828282]">{{ data.item.affiliator_email }}</span>
        </template>
        <template #cell(bank_name)="data">
          <span v-if="data.item.bank_name">{{ data.item.bank_name }}</span>
          <span v-else>-</span>
        </template>
        <template #cell(status)="data">
          <span
            :class="classStatus(data.item.status)"
            style="border-radius: 20px; padding: 0 10px; min-width: 100px !important"
          >
            {{ lableStatus(data.item.status) }}
          </span>
        </template>
        <template #cell(bank_account_no)="data">
          <div v-if="data.item.bank_account_no !== null">
            <span>{{ data.item.bank_account_no }}</span>
            <br>
            <span class="text-sm text-[#222222]">
              {{ data.item.bank_account_name }}
            </span>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(date_wd)="data">
          <span v-if="data.item.withdrawal_date">
            {{DAY_MONTH_YEAR(data.item.withdrawal_date )}}</span>
          <span v-else>-</span>
        </template>
      </BTable>
      <BRow>
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="bg-light d-flex justify-content-center align-items-center p-50 rounded">
            <span class="text-black mr-50"> List per halaman: </span>
            <BButton
              v-for="page in optionsPage"
              :key="page"
              class="btn-icon"
              size="sm"
              :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
              @click="setPerPage(page)"
            >
              {{ page }}
            </BButton>
          </div>

          <BPagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="totalPerPage"
            first-number
            last-number
            class="pagination-primary"
          >
            <template #prev-text>
              <feather-icon
                size="18"
                icon="ChevronLeftIcon"
              />
            </template>
            <template #next-text>
              <feather-icon
                size="18"
                icon="ChevronRightIcon"
              />
            </template>
          </BPagination>
        </BCol>
      </BRow>
    </BOverlay>
  </BCard>
</template>

<script>
import ToastificationContentVue from '@core/components/toastification/ToastificationContent.vue'
import DateRangePicker from 'vue2-daterange-picker'
import {
  firstDateOfMonth,
  affiliateDate,
  last2,
  last7,
  lastDateOfMonth,
  today,
} from '@/store/helpers'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, TIME, DAY_MONTH_YEAR,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { affiliateAxiosIns } from '@/libs/axios'
import { listWithdrawal, statusOptionsListWithdrawal } from './config'

export default {
  components: { DateRangePicker },
  data() {
    return {
      title: 'Antrian Penarikan Affiliate',
      isLoading: true,
      search: secureLocalStorage.get('searchWithdrawal') ? secureLocalStorage.get('searchWithdrawal') : '',
      items: [],
      fields: listWithdrawal,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [25, 50, 75, 100, 200],
      totalPerPage: 25,
      statusFilter: secureLocalStorage.get('statusWithdrawal') ? secureLocalStorage.get('statusWithdrawal') : null,
      statusOptions: statusOptionsListWithdrawal,
      dateRange: {
        startDate: secureLocalStorage.get('paramDateWithdrawalStartDate') ? secureLocalStorage.get('paramDateWithdrawalStartDate') : affiliateDate,
        endDate: secureLocalStorage.get('paramDateWithdrawalEndDate') ? secureLocalStorage.get('paramDateWithdrawalEndDate') : today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari Terakhir': [last7, today],
        'Bulan ini': [firstDateOfMonth, today],
        'Semua ': [affiliateDate, today],
      },
      today,
      last2,
      last7,
      lastDateOfMonth,
      firstDateOfMonth,
      YEAR_MONTH_DAY,
      IDR,
      TIME,
      DAY_MONTH_YEAR,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getListWhitdrawal()
      },
    },
    statusFilter: {
      handler(value) {
        secureLocalStorage.set('statusWithdrawal', value)
        this.getListWhitdrawal()
      },
    },
    dateRange: {
      handler(value) {
        secureLocalStorage.set('paramDateWithdrawalStartDate', YEAR_MONTH_DAY(value.startDate))
        secureLocalStorage.set('paramDateWithdrawalEndDate', YEAR_MONTH_DAY(value.endDate))
        this.getListWhitdrawal()
      },
    },
  },
  mounted() {
    this.getListWhitdrawal()
  },
  methods: {
    async getListWhitdrawal() {
      this.isLoading = true
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        filter: this.statusFilter,
        page: this.currentPage,
        per_page: this.totalPerPage,
        search: this.search,
      }
      const url = '/v1/withdrawal/admin/list'
      await affiliateAxiosIns.get(url, { params })
        .then(res => {
          const { data, total } = res.data.data
          this.items = data
          this.totalRows = total
          this.isLoading = false
        })
        .catch(err => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.message,
              variant: 'danger',
            },
          }, 2000)
          this.loading = false
        })
    },

    setPerPage(page) {
      this.totalPerPage = page
      this.getListWhitdrawal()
    },
    searchData: _.debounce(function search(text) {
      secureLocalStorage.set('searchWithdrawal', text)
      this.getListWhitdrawal()
    }, 1000),
    lableStatus(value) {
      if (value === 'requested') return 'Request Penarikan'
      if (value === 'on_review') return 'Sedang Direview'
      if (value === 'accepted') return 'Disetujui'
      if (value === 'reject') return 'Ditolak'
      if (value === 'in_process') return 'Diproses'
      return ''
    },
    classStatus(value) {
      if (value === 'requested') return 'requestedList'
      if (value === 'on_review') return 'on_reviewList'
      if (value === 'accepted') return 'completedList'
      if (value === 'reject') return 'rejectedList'
      if (value === 'in_process') return 'in_processList'
      return ''
    },
    handleToDetail(value) {
      const id = value[0].withdrawal_id
      this.$router.push({ path: `/affiliate/withdrawal/${id}` })
    },
  },
}
</script>

<style scoped lang="scss">
@import './Withdrawal.scss';
</style>
